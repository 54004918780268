<template>
  <div class="all_main_wrap">
    <!-- 头部 -->
    <div class="all_header_box">
      <div class="sider_left">
        <!-- 面包屑 -->
        <span class="title">
          <router-link
            class="crumbs_item"
            tag="a"
            to="/admin/Certificate/CertificateInformation"
            >证书信息 /
          </router-link>
          <span class="crumbs_item crumbs_last">修改</span>
        </span>
      </div>
    </div>
    <!-- 功能区 -->
    <div class="content_box">
      <!-- 确认信息 -->
      <div style="margin-bottom: 60px">
        <h3 class="big_title">确认信息</h3>
        <a-row>
          <a-col :span="7" class="book_flex"
            ><span class="left_name">姓名：</span
            ><a-input
              :disabled="examineeId != 1"
              v-model="userData.name"
              class="right"
              placeholder="请输入姓名"
          /></a-col>
          <a-col :span="7" class="book_flex"
            ><span class="left_name">性别：</span>
            <a-radio-group
              v-model="userData.sex"
              name="radioGroup"
              :default-value="1"
            >
              <a-radio :value="1">男</a-radio>
              <a-radio :value="0">女</a-radio>
            </a-radio-group>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="7" class="book_flex"
            ><span class="left_name">身份证号：</span
            ><a-input
              :disabled="examineeId != 1"
              v-model="userData.idcard"
              class="right"
              placeholder="请输入身份证号"
          /></a-col>
          <a-col :span="7" class="book_flex"
            ><span class="left_name">手机号码：</span
            ><a-input
              :disabled="examineeId != 1"
              v-model="userData.mobile"
              class="right"
              placeholder="请输入手机号"
          /></a-col>
        </a-row>
        <a-row>
          <a-col :span="7" class="book_flex"
            ><span class="left_name">职务/职称：</span
            ><a-input
              v-model="userData.post"
              class="right"
              placeholder="请输入职务/职称"
          /></a-col>
          <a-col :span="7" class="book_flex"
            ><span class="left_name">学历：</span
            ><a-input
              v-model="userData.education"
              class="right"
              placeholder="请输入学历"
          /></a-col>
        </a-row>
        <a-row>
          <a-col :span="7" class="book_flex"
            ><span class="left_name">出生日期：</span
            ><a-date-picker
              format="YYYY-MM-DD"
              v-model="userData.birthday"
              class="right"
          /></a-col>
          <a-col :span="7" class="book_flex"
            ><span class="left_name">工作单位：</span
            ><a-input
              v-model="userData.workUni"
              class="right"
              placeholder="请输入工作单位"
          /></a-col>
        </a-row>
      </div>
      <!-- 照片提交方式 -->
      <div style="margin-bottom: 60px">
        <h3 class="big_title">照片提交方式</h3>
        <a-radio-group v-if="isConfirm != 1" v-model="userData.mode">
          <a-radio :value="1"> 邮寄纸质照片 </a-radio>
          <a-radio :value="2"> 电子照片 </a-radio>
        </a-radio-group>

        <a-row v-if="userData.mode != 1">
          <a-col :span="7" class="book_flex" style="align-items: flex-start">
            <span class="left_name">1.电子照片：</span>
            <template>
              <a-upload
                name="avatar"
                list-type="picture-card"
                :showUploadList="false"
                accept=".jpg,.png,.jpeg,.JPG,.PNG,.JPEG"
                :beforeUpload="beforeUpload"
                :customRequest="customRequest"
              >
                <img
                  v-if="userData.picture"
                  :src="userData.picture"
                  alt="avatar"
                  width="600"
                />
                <div v-else>
                  <a-icon :type="loading ? 'loading' : 'plus'" />
                  <div class="ant-upload-text">上传照片</div>
                </div>
              </a-upload>
            </template>
          </a-col>
        </a-row>
        <a-row v-if="userData.mode != 2">
          <a-col :span="3" class="book_flex">
            <span class="left_name">2.邮寄纸质照片</span>
          </a-col>
          <a-col :span="4" offset="1" class="book_flex">
            <span class="left_name">快递公司： </span>
            <a-input
              v-if="isConfirm != 1"
              v-model="userData.logisticsCompany"
              placeholder="请输入快递公司"
            />
            <p v-else>{{ userData.logisticsCompany }}</p>
          </a-col>
          <a-col :span="4" class="book_flex">
            <span class="left_name">运单号： </span>
            <a-input
              v-if="isConfirm != 1"
              v-model="userData.logisticsNumber"
              placeholder="请输入运单号"
            />
            <p v-else>{{ userData.logisticsNumber }}</p>
          </a-col>
        </a-row>
      </div>
      <!-- 收货地址 -->
      <div>
        <h3 class="big_title">收货地址</h3>
        <a-row>
          <a-col :span="7" class="book_flex">
            <span class="left_name">收货人：</span>
            <a-input
              v-model="userData.userName"
              class="right"
              placeholder="请输入收货人"
            />
          </a-col>
          <a-col :span="5" class="book_flex">
            <span class="left_name">手机号：</span>
            <a-input
              v-model="userData.userMobile"
              class="right"
              placeholder="请输入手机号"
            />
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12" class="book_flex">
            <span class="left_name">收货地址：</span>
            <Province
              style="width: 400px"
              @regionCity="FunProvince"
              :value="addressValue"
            ></Province>

            <a-input
              style="margin-left: 10px"
              v-model="userData.userAddress"
              class="right"
              placeholder="请输入详细地址"
            />
          </a-col>
        </a-row>
      </div>
    </div>
    <div class="foot_btn">
      <a-button type="primary" class="btn" @click="pushData()">
        {{ isConfirm == 1 ? "确认" : "确认信息" }}
      </a-button>
      <a-button style="margin-left: 20px" class="btn" @click="$router.go(-1)"
        >取消</a-button
      >
    </div>
  </div>
</template>

<script>
import UE from "@/components/UE.vue";
import Province from "@/components/provinceCity";

export default {
  // 可用组件的哈希表
  components: { UE, Province },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      isConfirm: 0, //是否确认信息 是：1
      loading: false,
      imageUrl: undefined,
      ueInitText: "",
      selectVisible: false, // 选择商品弹窗
      loading: false, // 列表加载
      total: 0, // 总数量
      pageNumber: 1, //页码
      pageSize: 10, // 页数

      loading: false,
      imageUrl: "",
      // 用户数据
      userData: {
        birthday: null,
        certifName: null,
        education: null,
        examineeId: null,
        idcard: null,
        logisticsCompany: null,
        logisticsNumber: null,
        mobile: null,
        mode: null,
        name: null,
        picture: null,
        post: null,
        sex: null,
        userAddress: null,
        userMobile: null,
        userName: null,
        workUni: null,
        userProvince: null, //省
        userArea: null, //区
        userCity: null, //市
      }, //用户数据

      addressValue: [],
      examineeId: null, //用户id
    };
  },
  // 事件处理器
  methods: {
    // 获取省市区编码
    FunProvince(data) {
      this.addressValue = data;
    },
    // 分页
    onPage(e) {
      this.pageNumber = e.current;
      this.pageSize = e.pageSize;
    },
    // 富文本内容有变化时触发
    getContent(e) {
      this.$set(this, "ueGetText", e);
    },
    // 文件上传
    customRequest(fileData) {
      this.loading = true;

      const bucket = 'user/signup/' // *必填 文件存储地址（地址参考cosUpload.js说明文件）
      // 腾讯云对象存储上传文件
      this.$cosUpload(fileData.file,bucket,this.fileProgressCallback,(url, fileName) => {
        // 上传完成
        if (url) {
          this.userData.picture = url
        } else {
          this.$message.error("上传失败");
        }
        this.loading = false;
      });
    },

    // 文件上传更新进度和单文件上传初始化
    fileProgressCallback(progress, speed, name) {
      /*
       * progress 进度
       * speed 传输速度
       * name 文件名称
       * */
    },
    // 上传图片
    beforeUpload(file) {
      if (!this.$regular.imgReg.reg.test(file.name)) {
        this.$message.warning(this.$regular.imgReg.msg);
        return false;
      }
      // return this.upload.getToKen()
      const isJpgOrPng =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg";
      if (!isJpgOrPng) {
        this.$message.error("请上传正确格式图片文件!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("图片内存大小需在2MB之内");
      }
      return isJpgOrPng && isLt2M;
    },
    // 获取数据
    getData() {
      this.$ajax({
        url: "/hxclass-management/exam/certif/certif-manage/confirm-info",
        method: "get",
        params: {
          examineeId: this.examineeId,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.userData = res.data;
          this.addressValue = [
            res.data.userProvince,
            res.data.userCity,
            res.data.userArea,
          ];
        }
      });
    },
    // 修改数据
    pushData() {
      this.userData.userProvince = this.addressValue[0];
      this.userData.userCity = this.addressValue[1];
      this.userData.userArea = this.addressValue[2];
      this.userData.userArea = this.addressValue[2];
      // 验证非空字段
      if (!this.userData.userProvince) {
        this.$message.warning("请正确填写省");
        return;
      }
      if (!this.userData.mode) {
        this.$message.warning("请正确选择照片提交方式");
        return;
      }
      if (!this.userData.userName) {
        this.$message.warning("请正确填写考生收货人名称");
        return;
      }
      if (!this.userData.userMobile) {
        this.$message.warning("请正确填写考生收货人电话");
        return;
      }
      if (!this.userData.userProvince) {
        this.$message.warning("请正确填写考生收货地址省");
        return;
      }
      if (!this.userData.userCity) {
        this.$message.warning("请正确填写考生收货地址市");
        return;
      }
      if (!this.userData.userArea) {
        this.$message.warning("请正确填写考生收货地址区");
        return;
      }
      // 根据上传类型判断验证方法
      if (!this.userData.userAddress) {
        this.$message.warning("请正确填写考生收货地址详细地址");
        return;
      }
      if (this.userData.mode == 1) {
        if (!this.userData.logisticsCompany) {
          this.$message.warning("请正确填写物流公司名称");
          return;
        }
        if (!this.userData.logisticsNumber) {
          this.$message.warning("请正确填写物流单号");
          return;
        }
      } else {
        if (!this.userData.picture) {
          this.$message.warning("请上传电子照片");
          return;
        }
      }
      console.log(this.isConfirm);
      if (this.isConfirm == 0) {
        this.userData.isConfirm = 1;
        this.userData.examineeId = this.examineeId;
        this.$ajax({
          url: "/hxclass-management/exam/examinee/examinee/submit",
          method: "PUT",
          params: this.userData,
        }).then((res) => {
          if (res.code == 200 && res.success) {
            this.$message.success("操作成功");
            this.$router.go(-1);
          } else {
            this.$message.error(res.message);
            this.$router.go(-1);
          }
        });
      } else {
        this.$ajax({
          url: "/hxclass-management/exam/certif/certif-manage/edit",
          method: "PUT",
          params: this.userData,
        }).then((res) => {
          if (res.code == 200 && res.success) {
            this.$message.success("操作成功");
            this.$router.go(-1);
          } else {
            this.$message.error(res.message);
            this.$router.go(-1);
          }
        });
      }
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.examineeId = this.$route.query.examineeId;
    this.isConfirm = this.$route.query.isConfirm;
    if (this.examineeId != 1) {
      this.getData();
    }
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
a.crumbs_item {
  color: #333;
}
.content_box {
  padding: 20px 0;
  .big_title {
    font-size: 16px;
    color: #333333;
    font-weight: 500;
    margin-bottom: 20px;
  }
}
.left_name {
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  line-height: 26px;
  color: #333333;
}
// 必填标识
.required::before {
  content: "*";
  display: inline-block;
  color: red;
  position: relative;
  top: 2px;
  margin-right: 2px;
}
.ant-row {
  margin-bottom: 20px;
}
.ant-col-2 {
  text-align: right;
  margin-right: 10px;
}
.flex_Div {
  display: flex;
  align-items: center;
  .flex_left_Div {
    text-align: center;
    .size {
      color: red;
      font-size: 14px;
    }
  }
}

// 书籍信息
.book_flex {
  display: flex;
  align-items: center;
  > span {
    display: inline-block;
    text-align: right;
    min-width: 142px;
  }
  .right {
    width: 100%;
  }
}

// 系列购买
.series_box {
  margin-top: 50px;
  max-width: 800px;
  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    > span {
      display: inline-block;
      width: 140px;
      text-align: right;
      color: #333333;
    }
  }
  .table {
    margin-left: 140px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .series_flex {
    display: flex;
    align-items: center;
    .center {
      width: 80px;
      margin: 0 10px;
    }
    .time {
      font-size: 14px;
      margin-right: 10px;
    }
  }
}
.picture {
  width: 120px;
}
</style>
